<template>
  <v-dialog
    class="ma-0 pa-0"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    :width="$vuetify.breakpoint.smAndUp ? '940px' : '90%'"
  >
    <v-card class="dialog-card">
      <v-card-title class="dialog-title d-block">
        <h2>{{ $t("profile.set_language") }}</h2>
        <div class="subtitle-1">{{ $t("profile.language_description") }}</div>
        <div class="subtitle-1" v-if="$config.languageSupportEmail">
          {{ $t("profile.dont_see_yours") }}
          <a :href="'mailto:' + $config.languageSupportEmail">{{ $t("profile.tell_us") }}</a>
        </div>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="$i18n.locale"
          :items="languages"
          menu-props="auto"
          :label="$t('profile.select_language')"
          v-on:change="$store.commit('setLanguage', $i18n.locale)"
          hide-details
          prepend-icon="language"
          single-line
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          id="btn-done"
          color="black"
          depressed
          block
          class="btn-dark"
          @click="$emit('close')"
          >{{ $t("menu.done") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LanguageMixin from "./languageMixin";

export default {
  mixins: [LanguageMixin],
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
