const stickerPacks = {};
stickerPacks.ordering = [];
const stickerCodeMap = {};

class Stickers {
    constructor() {
    }

    loadStickersFromConfig(config) {
        const stickerConfig = config.shortCodeStickers;
        for (const pack of stickerConfig.packs) {
            stickerPacks[pack.name] = [];
            stickerPacks.ordering.push(pack.name);
            for (const sticker of pack.stickers) {
                const stickerName = sticker.split(".")[0];
                const stickerUrl = stickerConfig.baseUrl + pack.name + "/" + sticker;
                stickerPacks[pack.name].push({ image: stickerUrl, name: stickerName });
                stickerCodeMap[":" + pack.name + "-" + stickerName + ":"] = stickerUrl;
            }
        }
    }

    isStickerShortcode(messageBody) {
        if (messageBody && messageBody.startsWith(":") && messageBody.startsWith(":") && messageBody.length >= 5) {
            const image = this.getStickerImage(messageBody);
            return image != undefined && image != null;
        }
        return false;
    }

    getStickerShortcode(pack, sticker) {
        return ":" + pack + "-" + sticker.name + ":";
    }

    getPacks() {
        return stickerPacks.ordering;
    }

    getStickerImage(messageBody) {
        if (!messageBody) return null;
        if (messageBody.length < 5 || !messageBody.startsWith(":") || !messageBody.endsWith(":")) return null;
        return stickerCodeMap[messageBody];
    }

    stickersInPack(pack) {
        return stickerPacks[pack];
    }
}

var gStickers = new Stickers();
export default gStickers;
