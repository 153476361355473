<template>
  <v-dialog
    :value="showLogoutPopup"
    class="ma-0 pa-0"
    :width="$vuetify.breakpoint.smAndUp ? '688px' : '95%'"
    @click:outside="$emit('onOutsideLogoutPopupClicked')"
  >
    <div class="dialog-content text-center">
      <template>
        <h2 class="dialog-title">
         {{ $t("logout.confirm_text")}}
        </h2>
      </template>
      <v-container fluid>
        <v-row cols="12">
          <v-col cols="6">
            <v-btn
              depressed
              text
              block
              class="text-button"
              @click.stop="$emit('onCancelLogoutClicked')"
              >{{ $t("menu.cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" align="center">
            <v-btn
              color="red"
              depressed
              block
              class="filled-button"
              @click.stop="logout"
              >{{ $t("menu.logout") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-dialog>
</template>
<script>
import profileInfoMixin from "./profileInfoMixin";

export default {
  name: "LogoutRoomDialog",
  mixins: [profileInfoMixin],
  props: {
    showLogoutPopup: {
      type: Boolean,
      default: false
    }
  }
};
</script>
