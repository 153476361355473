<template>
  <message-incoming v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <audio controls :src="src">{{ $t("fallbacks.audio_file") }}</audio>
  </message-incoming>
</template>

<script>
import attachmentMixin from "../attachmentMixin";
import MessageIncoming from "../MessageIncoming.vue";

export default {
  name: "MessageIncomingAudioExport",
  extends: MessageIncoming,
  mixins: [attachmentMixin],
  components: { MessageIncoming },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
