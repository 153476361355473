<template>
  <div class="created-room-welcome-header">
    <div>{{ $t("room_welcome.info") }}</div>
    <div class="mt-2" v-if="roomJoinRule == 'public'">
      <i18n path="room_welcome.join_public" tag="span">
        <template v-slot:link>
          <div style="position:relative;display:inline-block">
            <a @click.stop="copyPublicLink" :href="publicRoomLink" class="text-break">{{ publicRoomLink }}</a>
            <v-btn
              v-if="publicRoomLinkCopied"
              id="btn-copy-room-link"
              color="#444444"
              depressed
              style="position:absolute;left:0;top:0"
              class="filled-button link-copied-in-place"
              >{{ $t("room_info.link_copied") }}</v-btn
            >
          </div>
        </template>
      </i18n>
    </div>
    <div class="mt-2" v-else-if="roomJoinRule == 'invite'">
      {{ $t("room_welcome.join_invite") }}
    </div>
    <div class="mt-2">{{ $t("room_welcome.info_permissions") }}</div>
    <div class="mt-2" v-if="roomIsEncrypted">
      {{ $t("room_welcome.encrypted") }}
    </div>
    <div class="mt-2" v-if="roomHistoryDescription">
      {{ roomHistoryDescription }}
    </div>
    <div class="text-end">
      <v-btn id="btn-got-it" text @click.stop="$emit('close')" class="text-transform-0">
        {{ $t("room_welcome.got_it") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import roomInfoMixin from "./roomInfoMixin";

export default {
  name: "CreatedRoomWelcomeHeader",
  mixins: [roomInfoMixin],
  computed: {
    roomHistoryDescription() {
      const visibility = this.$matrix.getRoomHistoryVisibility(this.room);
      switch (visibility) {
        case "world_readable":
          return this.$t("room_welcome.room_history_is", {
            type: this.$t("message.room_history_world_readable"),
          });
        case "shared":
          return this.$t("room_welcome.room_history_is", {
            type: this.$t("message.room_history_shared"),
          });
        case "invited":
          return this.$t("room_welcome.room_history_is", {
            type: this.$t("message.room_history_invited"),
          });
        case "joined":
          return this.$t("room_welcome.room_history_joined");
      }
      return null;
    },
  },
  data() {
    return {
      publicRoomLinkCopied: false
    }
  },
  methods: {
    copyPublicLink() {
      const self = this;
      this.$copyText(this.publicRoomLink).then(
        function (ignored) {
          // Success!
          self.publicRoomLinkCopied = true;
          setInterval(() => {
            // Hide again
            self.publicRoomLinkCopied = false;
          }, 3000);
        },
        function (e) {
          console.log(e);
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>