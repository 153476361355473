export default {
    install(Vue, defaultServerFromLocation) {
        var config = Vue.observable(require('@/assets/config.json'));
        const getRuntimeConfig = async () => {
            const runtimeConfig = await fetch('./config.json');
            return await runtimeConfig.json()
        }

        config.promise = getRuntimeConfig();
        config.promise.then(function (json) {
            // Reactively use all the config values
            for (const key of Object.keys(json)) {
                Vue.set(config, key, json[key]);
            }
            // If default server is not set, default to current server address
            if (!json.defaultServer) {
                Vue.set(config, "defaultServer", defaultServerFromLocation);
            }
        });
        Vue.prototype.$config = config;
    }
}
