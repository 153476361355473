export default {
  data() {
    return {
      rememberMe: this.$store.state.useLocalStorage
    }
  },
  methods: {
    onRememberMe(val) {
      this.$store.commit("setUseLocalStorage", val);
    }
  }
}