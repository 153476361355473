import cleaninsights from './cleaninsights.service'
import matomo from './matomo.service'

export default {
    install(Vue) {
        const analyticsService = new Vue({
            data() {
                return {
                    engines: [],
                    cachedEvents: [],
                    initialized: false
                }
            },
            created() {
                this.$config.promise.then((config) => {
                    var analytics = config.analytics || {};
                    if (!Array.isArray(analytics)) {
                        analytics = [analytics];
                    }
                    for (const engineConfig of analytics) {
                        if (engineConfig.enabled) {
                            let type = engineConfig.type || "ci";
                            switch (type) {
                                case "ci":
                                    {
                                        let engine = cleaninsights.install(Vue, engineConfig.config);
                                        this.engines.push(engine);
                                    }
                                    break;
                                case "matomo":
                                    {
                                        let engine = matomo.install(Vue, engineConfig.config);
                                        this.engines.push(engine);
                                    }
                                    break;
                                }
                        }
                    }

                    this.initialized = true;

                    // Handle cached events
                    this.cachedEvents.forEach(([category, action]) => {
                        this.event(category, action);
                    })
                    this.cachedEvents = [];
                });
            },
            methods: {
                event(category, action) {
                    if (!this.initialized) {
                        this.cachedEvents.push([category, action]);
                        return
                    }

                    // Send the event to each configured analytics engine.
                    this.engines.forEach((engine) => {
                        engine.event(category, action);
                    });
                }
            }
        });
        Vue.prototype.$analytics = analyticsService;
    }
}
