import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// Import all .vue icons and process them, so they can be used
// as $vuetify.icons.<iconname>
var icons = {}
function importAll(r) {
  return r.keys().map(res => {
      // Remove"./"
      const parts = res.split("/");
      const iconName = parts[1].split(".")[0];
      icons[iconName] = { component: r(res).default };
  });
}
importAll(require.context('@/assets/icons/', true, /\.vue$/));


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: icons,
  },
});
